import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Typography, Radio, Card, Divider, Space, Button, Switch, Tooltip, Modal } from "antd";
import PageTitle from "components/PageTitle";
import Parse from 'html-react-parser';
import { CreditCardIcon, LibraryIcon } from '@heroicons/react/outline';
import { Wallet, Scan, ChevronRight, EditSquare } from 'react-iconly';
import dayjs from "dayjs";
import _ from 'lodash';
import KycForm from "components/Forms/KycForm";
// styles
import 'assets/styles/checkout.scss';
// requests
import { subscribePaidPlan } from 'requests/subscribe';
import { getKyc } from 'requests/kyc';
import { setConfigAction as setConfig } from "redux/actions/config";
import { toast } from "react-toast";

const { Title } = Typography;

const Checkout = () => {
    const titles = [{ path: '/checkout', title: 'Checkout' }];

    // these values are set up in enum at backend
    const paymentOptions = [
        {
            title: 'Credit/Debit/ATM Card',
            value: 'cc',
            icon: <CreditCardIcon width={28} height={28} />
        },
        {
            title: 'UPI / QR',
            value: 'upi',
            icon: <Scan set='light' size={28} />
        },
        {
            title: 'Net Banking',
            value: 'bank',
            icon: <LibraryIcon width={28} height={28} />
        },
        {
            title: 'Wallet',
            value: 'wallet',
            icon: <Wallet set='light' size={28} />
        }
    ]

    const [loading, setLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [selectedType, setSelectedType] = useState('monthly');
    const [selectedPlan, setSelectedPlan] = useState({ currency: {} });
    const [estimatedSubtotal, setEstimatedSubtotal] = useState(0);
    const [estimatedExpiredDate, setEstimatedExpiredDate] = useState(dayjs());
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('cc');
    const [autoRenew, setAutoRenew] = useState(false);
    const [disabledOption, setDisabledOption] = useState(null);
    const [kycData, setKycData] = useState(null);
    const [visiblePlanDetails, setVisiblePlanDetails] = useState(false);
    const [visibleKycForm, setVisibleKycForm] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const config = useSelector(state => state.config);
    const authUser = useSelector(state => state.auth.authUser);
    const subscriptions = useSelector(state => state.auth.subscriptions);

    useEffect(() => {
        const getData = async () => {
            let headerConfig = { show_back_button: false };
            // check current subscription
            if (subscriptions.subscription) {
                setSubscription(subscriptions.subscription);
                headerConfig = { show_back_button: true };
            }

            dispatch(setConfig(headerConfig));

            // kyc (billing details)
            getKycData();
        }

        getData();
    }, [subscriptions]);

    useEffect(() => {
        if (state.selectedPlan) {
            setSelectedPlan(state.selectedPlan);
            setSelectedType(state.type);
        } else {
            navigate('/404', { replace: true });
        }
    }, [state]);

    useEffect(() => {
        if (selectedType === 'monthly') {
            setEstimatedSubtotal(Number(selectedPlan.monthly_price));
            setEstimatedExpiredDate(dayjs().add(1, 'month'));
        } else {
            setEstimatedSubtotal(Number(selectedPlan.monthly_price_paid_annual) * 12);
            setEstimatedExpiredDate(dayjs().add(1, 'year'));
        }
    }, [selectedPlan, selectedType]);

    // useEffect(() => {
    //     if (subscription && selectedPlan) {
    //         // if (subscription.plan_id === selectedPlan.id && !dayjs().isSame(subscription.end_date, 'date')) {
    //         const twoDaysBeforeExpired = dayjs(subscription.end_date).subtract(2, 'day');
    //         if (subscription.plan_id === selectedPlan.id && !dayjs().isSameOrAfter(twoDaysBeforeExpired, 'date')) {
    //             setDisabledOption(subscription.plan_type);
    //         }
    //     }
    // }, [subscription, selectedPlan]);

    const getKycData = async () => {
        try {
            const kycResponse = await getKyc();
            setKycData(kycResponse.kyc_information);
        } catch (err) {
            toast.error('An error occurred when fetch billing details');
        }
    }

    const onSubscribe = async () => {
        try {
            const isCompleteBillingInfo = kycData && kycData.billing_label && kycData.address && kycData.city && kycData.pincode && kycData.state_name;
            console.log(kycData.billing_label , kycData.address , kycData.city, kycData.pincode , kycData.state_name)
            if (!isCompleteBillingInfo) {
                toast.error('You have to complete billing information before subscribe');
                setVisibleKycForm(true);
                return;
            }

            setLoading(true);
            const response = await subscribePaidPlan(selectedPlan.id, selectedType, autoRenew ? 1 : 0);
            if (response.order) {
                window.open(`${process.env.REACT_APP_PAYMENT_URL}/payment/checkout/${selectedPaymentOption}?order_id=${response.order.id}`, '_self');
            }
        } catch (err) {
            console.log(err);
            toast.error(err.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const onCloseKycForm = () => {
        setVisibleKycForm(false);
        getKycData();
    }

    return (
        <div>
            <PageTitle titles={titles} />
            <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={16} lg={16}>
                    <Card className="mb-24">
                        {
                            subscription ? (
                                <Row align="middle">
                                    <div className="mr-32">
                                        <div><small className="uppercase">from</small></div>
                                        <Title level={4}>{subscription.plan_name}</Title>
                                    </div>
                                    <ChevronRight set="light" />
                                    <div className="ml-32">
                                        <div><small className="uppercase">change to</small></div>
                                        <Title level={4}>{selectedPlan.name}</Title>
                                    </div>
                                </Row>
                            ) : (
                                <div>
                                    <div><small className="uppercase">selected plan</small></div>
                                    <Title level={4}>{selectedPlan.name}</Title>
                                </div>
                            )
                        }
                        <Divider />
                        <Row justify="space-between">
                            <Radio.Group value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                <Space direction="vertical">
                                    <Radio value='monthly'>
                                        <div>Monthly Plan</div>
                                        <div><small>{selectedPlan.currency.symbol}{selectedPlan.monthly_price} billed monthly</small></div>
                                    </Radio>
                                    <Radio value='annual'>
                                        <div>Annual Plan</div>
                                        <div><small>{selectedPlan.currency.symbol}{selectedPlan.monthly_price_paid_annual}/mo billed annual. <b className="text-success">Save {selectedPlan.currency.symbol}{(Number(selectedPlan.monthly_price) - Number(selectedPlan.monthly_price_paid_annual)) * 12}</b></small></div>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            <div>
                                <small className="uppercase">till {estimatedExpiredDate.format('ddd, MMM D, YYYY')}</small>
                                <Title level={4} className="mb-0">{selectedPlan.currency.symbol}{estimatedSubtotal}</Title>
                            </div>
                        </Row>
                    </Card>
                    {/* Enable auto renew card */}
                    {/* <Card className="mb-24">
                        <Row justify="space-between" align="middle">
                            <Title level={5}>Enable auto renew</Title>
                            <Switch defaultChecked={autoRenew} onChange={(checked) => setAutoRenew(checked)} />
                        </Row>
                        <p>If this option is enabled, your current subscription will be renewed automatically when it is expired.</p>
                    </Card> */}
                    {/* Payment options */}
                    <div className="mb-24">
                        <Title level={4} className="mb-24">Payment options</Title>
                        {
                            paymentOptions.map(option => {
                                const isSelected = selectedPaymentOption === option.value;

                                return (
                                    <React.Fragment key={option.value}>
                                        <Card
                                            className={`mb-8 selectable-card ${isSelected ? 'selected-card' : ''}`}
                                            onClick={() => setSelectedPaymentOption(option.value)}
                                        >
                                            <Row align="middle" justify="space-between">
                                                <Row align="middle">
                                                    {option.icon}
                                                    <div className="ml-8">{option.title}</div>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    <div className="mb-24">
                        <Button type="primary" size="large" loading={loading} onClick={onSubscribe}>Pay {selectedPlan.currency.symbol}{estimatedSubtotal * 1.18} and Upgrade</Button>
                        {/* <div><small>The next charge will be made on <b>{estimatedExpiredDate.format('ddd, MMM D, YYYY')}</b></small></div> */}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                    <Card className="checkout-card-plan">
                        <Row justify="space-between" align="middle">
                            <Title level={3} className="mb-0">{selectedPlan.name}</Title>
                            <Button type="primary" shape="round" onClick={() => setVisiblePlanDetails(true)}>See plan details</Button>
                        </Row>
                    </Card>
                    {/* Billing detail */}
                    {
                        !_.isEmpty(kycData) && (
                            <div>
                                <Title level={4} className="mt-24">Billing details</Title>
                                <Card className="mt-16">
                                    <Row justify="space-between">
                                        <Title level={5}>{kycData.pan_owner_name || authUser.full_name}</Title>
                                        <Tooltip title="Edit" placement="topRight">
                                            <EditSquare className="link" set="light" width={20} height={20} onClick={() => setVisibleKycForm(true)} />
                                        </Tooltip>
                                    </Row>
                                    <div>{kycData.billing_label}</div>
                                    <div>{[kycData.address, kycData.pincode, kycData.city, kycData.state_name].filter(item => item).join(', ')}</div>
                                    <div>{kycData.gstin ? <span>GSTIN: {kycData.gstin}</span> : null}</div>
                                </Card>
                            </div>
                        )
                    }
                    {/* Summary */}
                    <Title level={4} className="mt-24">Summary</Title>
                    <Card className="mt-16">
                        <Row justify="space-between" align="middle">
                            <div>Subtotal</div>
                            <div><b>{selectedPlan.currency.symbol}{estimatedSubtotal}</b></div>
                        </Row>
                        <Row justify="space-between" align="middle" className="mt-16">
                            <div>GST (18%)</div>
                            <div><b>{selectedPlan.currency.symbol}{estimatedSubtotal * 0.18}</b></div>
                        </Row>
                        <Divider />
                        <Row justify="space-between" align="middle">
                            <Title level={5}>Total</Title>
                            <Title level={5}>{selectedPlan.currency.symbol}{estimatedSubtotal * 1.18}</Title>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* Plan details */}
            <Modal
                title={`Plan ${selectedPlan.name} details`}
                visible={visiblePlanDetails}
                footer={null}
                onCancel={() => setVisiblePlanDetails(false)}
            >
                <div>
                    {Parse(selectedPlan.description || '')}
                </div>
            </Modal>
            {/* Kyc form for update billing details */}
            <KycForm
                defaultType='form'
                defaultVisible={visibleKycForm}
                onOpen={() => setVisibleKycForm(true)}
                onClose={onCloseKycForm}
            />
        </div>
    )
}

export default Checkout;