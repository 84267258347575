import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Typography, Input, Card, Tooltip, Alert } from "antd";
import PageTitle from 'components/PageTitle';
import { LinkIcon } from '@heroicons/react/outline';
// request
import { verifyDomain } from 'requests/domain';
import { generateDomainStatus } from 'utils/common';
import regex from 'utils/regex';

const { Title } = Typography;

const Domain = () => {
    const [loading, setLoading] = useState(false);
    const [filledDomain, setFilledDomain] = useState('');

    const authUser = useSelector(state => state.auth.authUser);

    const location = useLocation();

    const titles = [{ path: location.pathname, title: 'Domain' }];

    const [formRef] = Form.useForm();

    const onSubmit = async (formData) => {
        try {
            setLoading(true);
            await verifyDomain(formData);
            formRef.resetFields();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="mb-36">
            <PageTitle titles={titles} />
            <Row>
                <Col sm={24} md={12} lg={12}>
                    {
                        authUser.domain ? (
                            <Card className='mb-24'>
                                <Title level={4} className='mb-16'>Your current domain</Title>
                                <div className='mb-8'>
                                    <a href={`http://${authUser.domain}`} target='_blank'>
                                        <span>{authUser.domain}</span>
                                        <Tooltip className='ml-8' title="Access domain">
                                            <LinkIcon className='view-store-icon' width={18} height={18} />
                                        </Tooltip>
                                    </a>
                                </div>
                                <div>
                                    {generateDomainStatus(authUser.domain_status)}
                                </div>
                            </Card>
                        ) : null
                    }
                    <Card className='mb-24'>
                        <Title level={4}>Verify new domain</Title>
                        <Form
                            form={formRef}
                            layout='vertical'
                            onFinish={onSubmit}
                        >
                            <Form.Item name='domain' label='Your domain' rules={[{ required: true, pattern: regex.domain }]}>
                                <Input onChange={(e) => setFilledDomain(e.target.value)} />
                            </Form.Item>
                            <p>
                                <ol className='pl-16'>
                                    <li>Log in to your domain registrar’s control panel.</li>
                                    <li>Create an A record for your <b>pay.example.com</b> domain and point it to our IP-address: <b>{process.env.REACT_APP_API_IP}</b></li>
                                    <li>
                                        <div>How to add sub-domain with:</div>
                                        <ul>
                                            <li><a href="https://support.wix.com/en/article/connecting-a-subdomain-to-an-external-resource">Wix</a></li>
                                            <li><a href="https://in.godaddy.com/help/add-a-subdomain-4080">GoDaddy</a></li>
                                            <li><a href="https://manage.bigrock.in/kb/node/636#heading_1">Bigrock.in</a></li>
                                            <li><a href="https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain/">Namecheap</a></li>
                                            <li><a href="https://support.google.com/a/answer/2579934?hl=en">Google</a></li>
                                        </ul>
                                    </li>
                                    <li>For any assistance on how to connect your domain, please Call or WhatsApp us on +911143503600.</li>
                                </ol>
                                <Alert
                                    description={<small><b>Tip: </b>Always create a new subdomain like <b>pay.domain.com</b> for routing payments. Do not use your main domain for the same where your website is hosted or linked with Ecwid.</small>}
                                    type="info"
                                />
                                <div className='mt-16'>
                                    <small>Changes take effect every hour. Once the setup is completed, your payments will be routed through your specified domain via a secure HTTPS connection encrypted by a SSL certificate.</small>
                                </div>
                            </p>
                            <Button type='primary' htmlType='submit' loading={loading}>Submit</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Domain;