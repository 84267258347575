import { useEffect } from 'react';
import { Button, Row, Tabs, Typography } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import GeneralSettings from './GeneralSettings';
import MessageTemplates from './MessageTemplates';

const { TabPane } = Tabs;
const { Title } = Typography;

export default function WhatsappSettings() {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.service === 'whatsapp') {
            if (!params.tab) {
                navigate('/notifications/settings/whatsapp/config');
            }
        }
    }, [location.pathname]);

    return (
        <Tabs
            tabPosition="left"
            className="whatsapp"
            activeKey={params.tab}
            defaultActiveKey={params.tab}
            destroyInactiveTabPane={true}
            onChange={(key) => navigate(`/notifications/settings/whatsapp/${key}`)}
        >
            <TabPane tab="General settings" key="config">
                <GeneralSettings />
            </TabPane>

            <TabPane tab="Templates" key="templates">
                <Row justify="space-between" align="middle" className='mb-16'>
                    <Title level={4}>Message templates</Title>
                    <a href='https://business.facebook.com/wa/manage/message-templates'>
                        <Button type="primary">Manage Templates</Button>
                    </a>
                </Row>
                <MessageTemplates />
            </TabPane>
        </Tabs>
    );
}
